import { AsyncPipe, CommonModule } from "@angular/common";
import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { FormsModule, NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { BadgeModule } from "primeng/badge";
import { ButtonModule } from "primeng/button";
import { DropdownChangeEvent, DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { MenuModule } from "primeng/menu";
import { SplitButtonModule } from "primeng/splitbutton";
import { interval, Subscription, switchMap, take, tap, timer } from "rxjs";
import { B2bHeaderService } from "../../../../projects/b2b-ecommerce/b2b-header/src/public-api";
import { CategoryDTO, PageRequestDTO } from "../../../../projects/b2b-ecommerce/b2b-models/src/lib/Entities";
import { CategoriesService } from "../../services/categories.service";
import { I18nService } from "../../utils/translate.service";
import { CartService } from "../../services/cart.service";
import { LoginService } from "../../services/login.service";
import { ThemeService } from "../../services/theme.service";
import { HeaderService } from "../../services/header.service";
import { ToastService } from "../../services/toast.service";

type AppTheme = "LIGHT" | "DARK";

interface Language {
  name: string;

  code: string;
}
interface Category {
  id: string | undefined;
  label: string | undefined;
}

interface SearchInterface {
  query: string;
  category: Category;
}
@Component({
  selector: "app-header",
  standalone: true,
  imports: [CommonModule, ButtonModule, SplitButtonModule, InputSwitchModule, DropdownModule, FormsModule, MenuModule, TranslateModule, BadgeModule, AsyncPipe],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.css",
})
export class HeaderComponent {
  @Input() defaultLanguage: string | null = "";
  @Output() selectedLanguageEmitter = new EventEmitter<string>();

  themeService = inject(ThemeService);
  translateService = inject(I18nService);
  loginService = inject(LoginService);
  router = inject(Router);
  cartService = inject(CartService);
  categoriesService = inject(CategoriesService);
  headerService = inject(HeaderService);
  toastService = inject(ToastService);

  categoriesPaginator: PageRequestDTO = {
    page: 0,
    size: 10,
  };

  localTheme: AppTheme | null = null;
  isMobileSize: boolean = false;
  isMenuVisible: boolean = false;
  categories: string[] = ["Corsi", "Ebook"];
  categorie: CategoryDTO[] = [];
  categoryOptions!: MenuItem[];
  selectedCategory: MenuItem | undefined;

  languages: Language[] = [
    { name: "IT", code: "IT" },
    { name: "ENG", code: "EN" },
  ];
  selectedLanguage: Language | undefined = undefined;

  accountOptions: MenuItem[] = [];
  isMobileSubscription: Subscription | undefined;
  loggedSubscription: Subscription | undefined;

  constructor() {
    this.localTheme = localStorage.getItem("currentTheme") as AppTheme;

    this.isMobileSubscription = this.headerService.getIsMobile().subscribe((isMobile) => {
      this.isMobileSize = isMobile;
      this.isMenuVisible = false;
    });

    if (this.loginService.isLoggedIn() && !this.loginService.isLoginGuest()) {
      timer(this.loginService.timeUntilTokenExpiration()-5*60*1000).subscribe(() => {
        interval(30000).pipe(
          switchMap(() => this.loginService.isTokenExpired()),
        ).subscribe((isExpired) => {
            if (isExpired) {
              this.loginService.resetAuthUser();
              this.router.navigate(["/home"]).then(() => {
                this.toastService.showErrorToast(this.toastService.loginToastMessages.tokenExpired[0], this.toastService.loginToastMessages.tokenExpired[1])
                window.location.reload();
              });
            }
        });
      })
    }
    
  }

  ngOnInit(): void {
    const startingLanguage = this.languages.find((x) => x.code.toLowerCase() === this.defaultLanguage);
    this.selectedLanguage = startingLanguage;

    this.accountOptions = this.buildMenu(this.loginService.isLoggedIn() && !this.loginService.isLoginGuest());
    this.loggedSubscription = this.loginService.isLoggedSubject$.pipe(take(1)).subscribe((result) => {
      if (result) {
        this.categoriesService.categories$.subscribe((categories) => {
          this.categorie = categories;
          this.categoryOptions = [
            ...this.categoryOptions,
            ...this.categorie.map((item) => ({
              label: item.name,
              id: item.uuid,
              command: (event: { item: MenuItem }) => {
                this.selectCategory(event.item);
              },
            })),
          ];
        });
      }
    });
  }

  buildMenu(isLogged: boolean): MenuItem[] {
    this.translateService.selectedLanguage$.subscribe((language) => {
      let allCategory;
      if (language == "it") {
        allCategory = {
          label: "Tutte le categorie",
          id: "all",
          command: (event: any) => {
            console.log(event);
            this.selectCategory(event.item);
          },
        };
      } else {
        allCategory = {
          label: "All categories",
          id: "all",
          command: (event: any) => {
            console.log(event);
            this.selectCategory(event.item);
          },
        };
      }

      this.categoryOptions = [
        allCategory,
        ...this.categorie.map((item) => ({
          label: item.name,
          id: item.uuid,
          command: (event: { item: MenuItem }) => {
            this.selectCategory(event.item);
          },
        })),
      ];
    });

    let menu = [{}];
    if (!isLogged) {
      menu = [
        {
          label: "header.SignIn",
          icon: "pi pi-sign-in",
          command: () => {
            console.log("go to login");
            this.router.navigateByUrl(`/login`);
            if (this.isMenuVisible) {
              this.toggleHamburgerMenu();
            }
          },
        },
        {
          label: "header.Register",
          icon: "pi pi-user-plus",
          command: () => {
            console.log("go to register");
            this.router.navigateByUrl(`/registration`);
            if (this.isMenuVisible) {
              this.toggleHamburgerMenu();
            }
          },
        },
      ];
    } else {
      menu = [
        {
          label: "header.MyAccount",
          icon: "pi pi-user",
          command: () => {
            this.router.navigateByUrl(`/account`);
            if (this.isMenuVisible) {
              this.toggleHamburgerMenu();
            }
          },
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: () => {
            console.log("logout");
            this.loginService.logout();
            if (this.isMenuVisible) {
              this.toggleHamburgerMenu();
            }
          },
        },
      ];
    }
    return menu;
  }

  switchTheme() {
    const theme: AppTheme = localStorage.getItem("currentTheme") as AppTheme;
    const nextTheme: AppTheme = theme === "DARK" ? "LIGHT" : "DARK";
    this.localTheme = nextTheme;

    this.themeService.setCssTheme(nextTheme);
  }

  selectLanguage(event: DropdownChangeEvent) {
    const language: Language = event.value as Language;
    console.log("event", event);
    this.selectedLanguageEmitter.emit(language.code);
    if (this.isMenuVisible) {
      this.toggleHamburgerMenu();
    }
  }

  searchWithQuery(searchRef: NgModel) {
    const value = searchRef.value;
    if (value) {
      const currentUrl = this.router.url.split("?")[0];
      this.router.navigate(["/category/all"], { queryParams: { search: value } });
      searchRef.reset();
    }
  }

  searchOnlyCategory(category: string) {
    const selectedCategory = this.categoryOptions.find((x) => x.id === category);
    if (selectedCategory) {
      console.log("Selected category: ", { label: selectedCategory.label, id: selectedCategory.id });
      this.router.navigateByUrl("category/" + category);
    }
  }

  selectCategory(item: MenuItem | undefined) {
    if (item) {
      this.selectedCategory = item;
      this.router.navigate(["/category/" + item.id]);
    }
  }

  goToHome() {
    this.router.navigateByUrl("home");
    if (this.isMenuVisible) {
      this.toggleHamburgerMenu();
    }
  }

  goToCart() {
    this.router.navigateByUrl("cart");
    if (this.isMenuVisible) {
      this.toggleHamburgerMenu();
    }
  }

  toggleHamburgerMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }
}
