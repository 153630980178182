import { Routes } from "@angular/router";
import { authGuard, authGuardDialog, vendorGuard } from "../../projects/b2b-ecommerce-lib/src/public-api";


export const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "home",
    loadComponent: () => import("./components/homepage/homepage.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "account",
    loadComponent: () => import("./components/account/account.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "account/personal",
    loadComponent: () => import("./components/account/personal/personal.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "account/payments",
    loadComponent: () => import("./components/account/my-payment-methods/my-payment-methods.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "account/address",
    loadComponent: () => import("./components/account/my-addresses/my-addresses.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "account/orders",
    loadComponent: () => import("./components/account/my-orders/my-orders.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "account/refund",
    loadComponent: () => import("./components/account/my-refunds/my-refunds.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "account/products",
    loadComponent: () => import("./components/account/my-products/my-products.component"),
    data: { requiresAuthentication: false },
    canActivate: [vendorGuard],
  },
  {
    path: "account/backoffice",
    loadComponent: () => import("./components/homepage/homepage.component"),
    data: { requiresAuthentication: false },
    canActivate: [authGuard],
  },
  {
    path: "login",
    loadComponent: () => import("./components/login/login.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "login/:logout",
    loadComponent: () => import("./components/login/login.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "forgot-password",
    loadComponent: () => import("./components/login/forgot-password/forgot-password.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "password-reset",
    loadComponent: () => import("./components/login/set-password/set-password.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "registration",
    loadComponent: () => import("./components/registration/registration.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "registration/:confirm",
    loadComponent: () => import("./components/registration/registration.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "cart",
    loadComponent: () => import("./components/cart/cart.component"),
    canActivate: [authGuardDialog],
  },
  {
    path: "checkout",
    loadComponent: () => import("./components/checkout/checkout.component"),
    canActivate: [authGuardDialog],
  },
  {
    path: "checkout/:id",
    loadComponent: () => import("./components/checkout/checkout.component"),
    canActivate: [authGuardDialog],
  },
  {
    path: "summary-order",
    loadComponent: () => import("./components/summary-order/summary-order.component"),
    canActivate: [authGuardDialog],
  },
  {
    path: "summary-order/:id",
    loadComponent: () => import("./components/summary-order/summary-order.component"),
    canActivate: [authGuardDialog],
  },
  {
    path: "confirm-order",
    loadComponent: () => import("./components/confirm-order/confirm-order.component"),
    canActivate: [authGuardDialog],
  },
  {
    path: "products",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "product-detail/:id",
    loadComponent: () => import("./components/products/product-detail/product-detail.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "products/:filter",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "products/:filter/:page/:size/:idsottocategoria",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },

  {
    path: "category/:idCategory",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "category/:idCategory/:page/:size/:idsottocategoria",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "category/:idCategory/:filter",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },
  {
    path: "category/:idCategory/:filter/:page/:size/:idsottocategoria",
    loadComponent: () => import("./components/products/products-list/products-list.component"),
    data: { requiresAuthentication: false },
  },

  {
    path: "product/:idCategory/:id",
    loadComponent: () => import("./components/products/product-detail/product-detail.component"),
    data: { requiresAuthentication: false, fromProdotto: true },
  },
  {
    path: "account/products/add-product",
    loadComponent: () => import("./components/products/product-crud/product-crud.component"),
  },
  {
    path: "account/products/edit/:idarticolo",
    loadComponent: () => import("./components/products/product-crud/product-crud.component"),
  },
  {
    path: "review-form/:idItem",
    loadComponent: () => import("./components/reviews/review-form/review-form.component"),
  },
  {
    path: "info",
    loadComponent: () => import("./components/information/information.component"),
  },
  {
    path: "info/:topic",
    loadComponent: () => import("./components/information/information.component"),
  },
  {
    path: "**",
    loadComponent: () => import("./components/page-not-found/page-not-found.component"),
  },
];
