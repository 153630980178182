import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { I18nService } from "../utils/translate.service";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  TOAST_KEY: string = "global";
  STICKY: boolean = true;
  public cartToastMessages!: cartToastMessagesClass
  public loginToastMessages!: loginToastMessageClass
  public routeGuardToastMessages!: routeGuardToastMessageClass
  public appToastMessages!: appToastMessageClass
  public addressesToastMessages!: addressesToastMessageClass
  public productToastMessages!: productToastMessageClass

  constructor(private msgService: MessageService, private translateService: I18nService) {

    this.translateService.selectedLanguage$.subscribe((language) => {

      if (language === "it") {

        this.loginToastMessages = {
          logoutSuccess: ["Successo", "Logout effettuato con successo"],
          loginSuccess: ["Successo", "Login effettuato con successo"],
          errorAccountLocked: ['Errore', 'Account non ancora abilitato: controllare la casella postale e verificare la mail.'],
          errorBadCredentials: ['Errore', 'Credenziali errate'],
          errorUserNotFound: ['Errore', 'Utente non trovato'],
          emptycredentials: ['Errore', 'Inserire email e password'],
          emptyEmail: ['Errore', 'Compilare il campo \"Email\"'],
          emptyPassword: ['Errore', 'Compilare il campo \"Password\"'],
          tokenExpired: ['Errore', 'Sessione scaduta. Effettuare nuovamente il login.']
        }
      
        this.cartToastMessages = {
          empty: ["Vuoto", "Il carrello è vuoto"],
          retrieveError: ["Errore", "Si è verificato un errore durante il recupero degli articoli del carrello"],
          qtyUpdateSuccess: ["Successo", "Quantità aggiornata"],
          genericError: ["Errore", "Si è verificato un errore durante l'operazione"],
          deleteSuccess: ["Successo", "Articolo rimosso"],
          workInProgress: ["Work in progress", "Checkout ancora non disponibile!"],
        };

        this.routeGuardToastMessages = {
          notLoggedCart: ["Loggati per visualizzare il carrello", "Per favore effettua il login"],
          notLoggedGeneric: ["Non sei loggato", "Per favore effettua il login"],
          notVendor: ["Non sei un venditore", "Accesso non consentito per questo url"],
        }

        this.appToastMessages = {
          sessionExpired: ["Errore", "La sessione è scaduta. Riavvia l'applicazione"],
        }

        this.addressesToastMessages = {
          newSuccess: ["Successo", "Indirizzo inserito correttamente"],
          editSuccess: ["Successo", "Indirizzo modificato correttamente"],
          deleteSuccess: ["Successo", "Indirizzo eliminato correttamente"],
          defaultSuccess: ["Successo", "Indirizzo impostato come predefinito"],
          defaultError: ["Errore", "Errore durante la modifica dello stato di predefinito dell' indirizzo"],
          deleteError: ["Errore", "Errore durante l' eliminazione dell' indirizzo"],
          genericError: ["Errore", "Si è verificato un errore durante l'operazione"],
        }

        this.productToastMessages = {
          productAlreadyInCart: ["Avviso", "Prodotto già nel carrello"],
          addToCartSuccess: ["Successo", "Prodotto aggiunto al carrello con successo"],
          genericError: ["Errore", "Si è verificato un errore durante l'operazione"],
          retrieveListError: ["Errore", "Impossibile recuperare la lista degli articoli"],
          notLoggedIn: ["Errore", "Devi essere loggato per eseguire questa operazione"],
        }
        
      } else if (language === "en") {

        this.loginToastMessages = {
         logoutSuccess: ["Success", "Logout successfully performed"],
         loginSuccess: ["Success", "Login successfully performed"],
         errorAccountLocked: ['Error', 'Account not yet enabled: check you email inbox and verify your email'],
         errorBadCredentials: ['Error', 'Bad credentials'],
         errorUserNotFound: ['Error', 'User not found'],
         emptycredentials: ['Error', 'Enter your email and password'],
         emptyEmail: ['Error', 'Enter an email'],
         emptyPassword: ['Error', 'Enter a password'],
         tokenExpired: ['Error', 'Session expired. Please log in again.']
        }

        this.cartToastMessages = {
          empty: ["Empty", "Cart is empty"],
          retrieveError: ["Error", "There was an error while retrieving cart items"],
          qtyUpdateSuccess: ["Success", "Quantity updated"],
          genericError: ["Error", "An error occurred during the operation"],
          deleteSuccess: ["Success", "Item deleted"],
          workInProgress: ["Work in progress", "Checkout still not available!"],
        }

        this.routeGuardToastMessages = {
          notLoggedCart: ["Log in to view the cart", "Please log in"],
          notLoggedGeneric: ["You are not logged in", "Please log in"],
          notVendor: ["You are not a vendor", "Access not allowed for this url"]
        }

        this.appToastMessages = {
          sessionExpired: ["Error", "Your session has expired. Please reload the application"],
        }

        this.addressesToastMessages = {
          newSuccess: ["Success", "Address successfully added"],
          editSuccess: ["Success", "Address successfully edited"],
          deleteSuccess: ["Success", "Address successfully deleted"],
          defaultSuccess: ["Success", "Address set as default"],
          defaultError: ["Error", "Error while changing the default address status"],
          deleteError: ["Error", "Error while deleting the address"],
          genericError: ["Error", "An error occurred during the operation"],
        }

        this.productToastMessages = {
          productAlreadyInCart: ["Warning", "Product already in cart"],
          addToCartSuccess: ["Success", "Product added to cart successfully"],
          genericError: ["Error", "An error occurred during the operation"],
          retrieveListError: ["Error", "There was an error while retrieving product list"],
          notLoggedIn: ["Error", "You must be logged in to perform this operation"],
        }

      }
    });
  }
    
  showSuccessToast(summary: string, detail: string) {
    this.showToast(summary, detail, "success");
  }

  showInfoToast(summary: string, detail: string) {
    this.showToast(summary, detail, "info");
  }

  showWarnToast(summary: string, detail: string) {
    this.showToast(summary, detail, "warn");
  }

  showErrorToast(summary: string, detail: string) {
    this.showToast(summary, detail, "error");
  }

  private showToast(summary: string, detail: string, severity: string) {
    this.msgService.add({
      key: this.TOAST_KEY,
      severity: severity,
      summary: summary,
      detail: detail,
    });
  }
}

class cartToastMessagesClass {
  empty!: [string, string];
  retrieveError!: [string, string];
  qtyUpdateSuccess!: [string, string];
  genericError!: [string, string];
  deleteSuccess!: [string, string];
  workInProgress!: [string, string];
}
  
class loginToastMessageClass {
  logoutSuccess!: [string, string];
  loginSuccess!: [string, string];
  errorAccountLocked!: [string, string];
  errorBadCredentials!: [string, string];
  errorUserNotFound!: [string, string];
  emptycredentials!: [string, string];
  emptyEmail!: [string, string];
  emptyPassword!: [string, string];
  tokenExpired!: [string, string];
}

class routeGuardToastMessageClass {
  notLoggedCart!: [string, string];
  notLoggedGeneric!: [string, string];
  notVendor!: [string, string];
}

class appToastMessageClass {
  sessionExpired!: [string, string];
}

class addressesToastMessageClass {
  newSuccess!: [string, string];
  editSuccess!: [string, string];
  deleteSuccess!: [string, string];
  defaultSuccess!: [string, string];
  defaultError!: [string, string];
  deleteError!: [string, string];
  genericError!: [string, string];
}

class productToastMessageClass {
  productAlreadyInCart!: [string, string];
  addToCartSuccess!: [string, string];
  genericError!: [string, string];
  retrieveListError!: [string, string];
  notLoggedIn!: [string, string];
}