<nav
  class="surface-section lg:py-3 pl-1 pr-6 lg:px-6 shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 surface-border"
  style="min-height: 84px"
>
  @if (isMobileSize) {
    <img src="assets/images/sapientialogopiccolo2@3x.webp" alt="Sapientia Logo" height="100" class="mr-0 cursor-pointer" (click)="goToHome()" />
  } @else {
    <img src="assets/images/sapientialogo.webp" alt="Sapientia Logo" height="70" class="mr-3 cursor-pointer" (click)="goToHome()" />
  }
  <!-- Hamburger Menu Start -->
  <div class="p-ripple p-element cursor-pointer block lg:hidden text-600 h-2rem" (click)="toggleHamburgerMenu()">
    <i class="pi pi-bars text-4xl"></i>
  </div>
  <!-- Hamburger Menu End -->
  <div
    class="align-items-center flex-grow-1 lg:flex absolute lg:static w-full surface-section left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none surface-border"
    [ngClass]="{ hidden: !isMenuVisible && isMobileSize }"
  >
    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row flex-grow-1">
      <li class="flex-order-0 flex-grow-1">
        <div class="flex px-6 p-3 lg:px-3 lg:py-2">
          <!-- Wrappo input per aggiungere iconcina search -->
          <p-splitButton
            [label]="selectedCategory !== undefined ? selectedCategory.label : ('header.All' | translate)"
            [model]="categoryOptions"
            styleClass="p-button-sm p-button-outlined categories-button"
          ></p-splitButton>
          <span class="p-input-icon-right w-full">
            <i class="pi pi-search text-600 absolute cursor-pointer" style="top: 12px" (click)="searchWithQuery(searchRef)"></i>
            <input
              type="text"
              placeholder="{{'header.searchSapientia'|translate}}"
              ngModel
              #searchRef="ngModel"
              class="p-inputtext p-component p-element b2b-header surface-section text-600 surface-border w-full"
              (keyup.enter)="searchWithQuery(searchRef)"
            />
          </span>
        </div>
      </li>
      <!-- Scelta Lingua -->
      <li class="mb-2 lg:mb-0">
        <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" placeholder="Lingua" class="px-6 p-3 lg:px-3 lg:py-2 my-2 lg:my-0" (onChange)="selectLanguage($event)">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedLanguage">
              <span [class]="'flag flag-' + selectedLanguage.code.toLowerCase()" style="width: 18px"></span>
              <span>{{ selectedLanguage.name }}</span>
            </div>
          </ng-template>
          <ng-template let-language pTemplate="item">
            <div class="flex align-items-center gap-2">
              <span [class]="'flag flag-' + language.code.toLowerCase()" style="width: 18px"></span>
              <span>{{ language.name }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </li>
      <!-- Account Settings -->
      <li>
        <a
          class="p-ripple p-element flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:surface-hover hover:text-blue-500 font-medium lg:border-round cursor-pointer transition-colors transition-duration-150 mr-2"
          (click)="accountMenu.toggle($event)"
        >
          <i class="pi pi-user mr-2"></i>
          <span class="font-medium">{{ "header.Account" | translate }}</span>
        </a>
        <p-menu #accountMenu [model]="accountOptions" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <a pRipple class="flex align-items-center p-menuitem-link" >
              <span [class]="item.icon"></span>
              <span class="ml-2">{{ item.label | translate }}</span>
          </a>
        </ng-template>
        </p-menu>
      </li>
      <!-- Cart -->
      <li>
        <a
          class="p-ripple p-element flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:surface-hover hover:text-blue-500 font-medium lg:border-round cursor-pointer transition-colors transition-duration-150 mr-2"
          (click)="goToCart()"
        >
          @if (cartService.isLoadingCart) {
            <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem"></i>
          } @else {
            <i class="pi pi-shopping-cart mr-2" pBadge [value]="cartService.numberOfArticles().toString()"></i>
            <span class="font-medium">{{ "header.Cart" | translate }}</span>
          }
        </a>
      </li>
      <!-- Dark & Light mode switch -->
      <li>
        <button
          type="button"
          class="border-1 border-solid w-2rem h-2rem surface-border border-round surface-section align-items-center justify-content-center cursor-pointer mx-6 lg:mx-2 my-2 lg:my-0 transition-all transition-duration-300 hover:border-primary"
          (click)="switchTheme()"
        >
          @if (localTheme === "DARK") {
            <i class="pi pi-moon"></i>
          } @else {
            <i class="pi pi-sun"></i>
          }
        </button>
      </li>
    </ul>
  </div>
</nav>
<div class="flex align-items-center flex-grow-1 w-full border-bottom-1 surface-border surface-section">
  <ul class="list-none container overflow-auto mx-auto px-3 py-2 flex gap-3 align-items-center flex-grow-1">
    @for (category of categorie; track $index) {
      <li class="cursor-pointer hover:text-blue-500 transition-colors transition-duration-150" (click)="searchOnlyCategory(category.uuid)">
        {{ category.name }}
      </li>
    } @empty {
      <li>{{'header.noCategoryFound' | translate}}</li>

    }
  </ul>
</div>
